<template lang="">
    <div class="vh-center   ">
        <b-row>
    
            <b-col cols="12" md="6">
                <b-card header="ຄຳແນະນຳ ການປະກອບຄຳຮ້ອງຂໍໜັງສືຜ່ານແດນ" class="text-wrap " footer-tag="footer">
                    <div style="height:300px; overflow-y: scroll;" v-scroll:throttle="{fn: hasVerticalScroll, throttle: 300 }" ref="law">
                        1. ຂຽນຄໍາຮອ້ງຂໍໜັງສືຜ່ານແດນຕາມໃບຄໍາຮ້ອງ ເຊິ່ງສາມາດດາວໂລດໄດ້ຈາກເວັບໄຊຂອງກະຊວງການຕ່າງປະເທດ ຕາມທີ່ຢູ່ນີ້
                        <b-link href="http://www.mofa.gov.la/index.php/lo/">http://www.mofa.gov.la/index.php/lo/</b-link> ຫຼື
                        <b-link href=" http://www.mofa.gov.la/index.php/lo/passport_application"> http://www.mofa.gov.la/index.php/lo/passport_application</b-link><br> 2. ໃບຄໍາຮອ້ງຂໍໜັງສືຜ່ານແດນ ຕອ້ງຕິດຮູບສີ(ພື້ນສີຂາວ) ຂະໜາດ 4 x 6 ຊມ ພ້ອມທັງປະທັບການາຍບ້ານ;<br> 3. ບຸກຄົນທີ່ມາເຮັດໜັງືຜ່ານແດນ ຕ້ອງໄດ້ມາຍື່ນເອກະສານດ້ວຍຕົນເອງ ເພື່ອຖ່າຍຮູບ
                        (ແຕ່ງກາຍສຸພາບ ແລະ ບໍ່ໃຫ້ໃສ່ເສື້ອສີຂາວ) ແລະ ເກັບລາຍນິ້ວມື;<br> 4. ກໍລະນີພໍ່ ແລະ ແມ່ ຂອງບຸກຄົນທີ່ມາເຮັດໜັງສືຜ່່ານແດນບໍ່ໄດ້ຢູ່ໃນສໍາມະໂນຄົວເຫຼັ້ມດຽວກັນ ຕ້ອງຖືໜັງສືຢັ້ງຢືນການເກີດ ຈາກຫ້ອງການພາຍໃນຂອງເມືອງ ແລະ ໃບແຕ່ງດອງຂອງພໍ່ ແລະ ແມ່ ສະບັບແທ້ມານໍາ
                        ພ້ອມທັງສໍາເນົາຈໍານວນ 01 ຊຸດ ປະກອບໃສ່ໃບຄໍາຮອ້ງ; <br> 5. ສໍາລັບຜູ້ທີ່ມີອາຍຸຕໍ່າກວ່າ 18 ປີຕ້ອງແມ່ນຜູ້ປົກຄອງພາມາ;<br> 6. ບຸກຄົນທີ່ເຮັດໜັງສືຜ່ານແດນເສຍ ຕ້ອງມີໃບແຈ້ງເສຍຫາຍຈາກເຈົ້າໜ້າທີ່ ປກສ ຂັ້ນເມືອງ ຫຼື ແຂວງ ຫຼື ນະຄອນຫຼວງ;<br> 7. ບຸກຄົນທີ່ມີໜັງສືຜ່ານແດນແລ້ວ
                        ຖ້າຕ້ອງການປ່ຽນຊື່ ຫຼື ນາມສະກຸນ ຫຼື ຕື່ມນາມສະກຸນ ຕອ້ງຖືໃບອະນຸຍາດ ຈາກພະແນກ ພາຍໃນເມືອງ ສະບັບແທ້ພອ້ມທັງສໍາເນົາຈໍາ ນວນ 01 ຊຸດປະກອບໃສ່ ໃບ ຄໍາຮອ້ງ;<br> 8. ບຸກຄົນທີ່ມີພໍ່ ຫຼື ແມ່ ເປັນຄົນຕ່າງປະເທດ ຕ້ອງຖືໃບແຕ່ງດອງສະບັບແທ້ຂອງ ພໍ່ແມ່ ມານໍາ ພອ້ມທັງສໍາເນົາຈໍາ
                        ນວນ 01 ຊຸດ ປະກອບໃສ່ ໃບຄໍາຮອ້ງຂໍໜັງສືຜ່ານແດນ. ຖ້າແຕ່ງດອງຢູ່ຕ່າງປະເທດ ໃບແຕ່ງດອງດັ່ງກ່າວ ຕ້ອງຜ່ານການຢັ້ງຢືນຈາກ ສະຖານເອກອັກຄະລັດຖະທູດ ຫຼື ກົງສູນໃຫຍ່ ແຫ່ງ ສປປ ລາວ ປະຈໍາຢູ່ ຫຼື ຮັບຜິດຊອບປະເທດນັ້ນ; <br> 9. ບຸກຄົນທີ່ມາຂໍປ່ຽນໜັງສືຜ່ານແດນ ກໍລະນີອາໄສຢູ່ຕ່າງປະເທດ
                        ຕ້ອງມີໃບນໍາສົ່ງຂອງສະຖານທູດລາວ ປະຈໍາ ຢູ່ປະເທດທີ່ຕົນອາໄສຢູ່ ປະກອບໃສ່ຊຸດໃບຄໍາຮ້ອງຂໍ ປ່ຽນໜັງສືຜ່ານແດນ;<br> 10. ບຸກຄົນທີ່ສະກົດຊື່ ແລະ ນາມສະກຸນ ພາສາຕ່າງປະເທດ ທີ່ຂັດກັບຫຼັກຂອງວິຊາການ (ສະກົດແບບພາສາຝຣັ່ງ ຕົວຢ່າງ: Mr. Seng ແຕ່ສະກົດ Mr. Saeng
                        ຫຼື Mr. Phothisay ແຕ່ສະກົດ Mr. Bodhisay) ຕ້ອງຖືເອກະສານ ສະບັບແທ້ພ້ອມ ທັງສໍາເນົາຈໍານວນ 01 ຊຸດ ທີ່ທາງການເຄີຍອອກໃຫ້ເຊັ່ນ: ໃບປະກາດ ຫຼື ເອກະສານທາງການອື່ນໆ ມາຢັ້ງຢຶນຕໍ່ເຈົ້າໜ້າທີ່ກ່ຽວຂ້ອງຂອງກົມກົງສູນ;<br> 11. ຄົນຕ່າງດ້າວທີ່ໄດ້ສັນຊາດລາວ ຕ້ອງຖືເອກະສານ
                        ການໄດ້ຮັບສັນຊາດລາວທີ່ຖືກຕອ້ງຈາກພາກສ່ວນກ່ຽວຂອ້ງ ສະບັບແທ້ ພ້ອມສໍາເນົາຈໍານວນ 1 ຊຸດປະກອບໃສ່ໃບຄໍາຮອ້ງ;<br> 12. ສໍາລັບພະສົງ, ສໍາມະເນນ ແລະ ແມ່ຂາວ ຕ້ອງມີໃບຢັ້ງຢືນຈາກອົງການພຸດທະສາສະໜາຂອງແຂວງ ຫຼື ກົມສາສະໜາຂອງ ສູນກາງແນວລາວສ້າງຊາດ, ຕອ້ງຖືປື້ມສຸດທິ
                        ແລະ ສໍາມະໂນຄົວສົງ ສະບັບແທ້ພ້ອມທັງສໍາເນົາຈໍານວນ 1 ຊຸດ ປະກອບໃສ່ໃບຄໍາຮອ້ງຂໍໜັງສືຜ່ານແດນ.<br> 13. ນອກຈາກເອກະສານທີ່ກ່າວມາຂ້າງເທິງນີ້ , ເຈົ້າໜ້າທີ່ອາດຈະຂໍເອກະສານອື່ນໆທີ່ກ່ຽວຂ້ອງເພີ່່ມເຕີມ ເພື່ອປະກອບໃສ່ ໃບຄໍາຮອ້ງຂໍໜັງສືຜ່ານແດນ ຕາມແຕ່ລະກໍລະນີ.
    
                    </div>
    
                    <template #footer>
                        <b-row>
                            <b-col>  <b-button block variant="primary" to="/" >ຍັງບໍ່ທັນອ່ານ</b-button></b-col>
                            <b-col> <b-button block variant="primary" :disabled="hasScroll"  to="/registerinfo"  >ອ່ານ ແລະ ຮັບຊາບແລ້ວ >> </b-button></b-col>
                        </b-row>
                              
                                 
                               
</template>
                </b-card>
    
            </b-col>
        </b-row>
    
    
    
    </div>
</template>

<script>
import axios from "axios";

export default {
    created() {
        axios
            .get(
                this.$store.getters.getBaseUrl + "/getItems/consular_units/unit_name/unit_id"
            )
            .then((response) => {
                // JSON responses are automatically parsed.
                this.units = response.data

            })
            .catch((e) => {
                console.log(e);
            });
    },

    methods: {
        onNext() {
            let info = JSON.stringify(this.fld)
            this.$router.push('/pageqa1/' + this.fld.age + "?c=" + info)
        },
        onDateDiff() {
            let d2 = new Date();
            let d1 = new Date(this.fld.dob);

            let diff = d2.getTime() - d1.getTime();

            this.fld.age = Math.ceil(diff / (1000 * 60 * 60 * 24) / 365);
        },
        onGetRangefree() {
            axios
                .get(
                    this.$store.getters.getBaseUrl + "/getqueu_remain?unit_id=" + this.fld.unit_id + "&queue_date=" + this.fld.queue_date,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
                            "Accept-Encoding": "identity"
                        }
                    }
                )
                .then((response) => {
                    // JSON responses are automatically parsed.
                    this.ranges = response.data

                })
        },
        hasVerticalScroll(e, position) {
            //   alert(document.body.offsetHeightl)
            //  console.log( position)
            if (position.scrollTop > 200) {
                this.hasScroll = false
            } else {
                this.hasScroll = true
            }
            this.obj = e

            this.position = position;

        },

        // onPostRTIS() {


        // axios.post('https://www.rtis.gov.la/tims/lib/api/getacc8',  
        // )
        // .then(  (response)=> {
        //     this.t=response.data
        // })
        // .catch(  (error)=> {
        //     console.log(error)
        // })
        // }
    },
    data() {
        return {
            hasScroll: true,
            obj: "",
            position: 0,
            fld: {
                fname: "",
                lname: "",
                sex: "",
                dob: "",
                unit_id: 1
            },
            sex: [
                { item: 'M', name: 'ຊາຍ' },
                { item: 'F', name: 'ຍິງ' },
            ],
            age: '',
            dob: '',
            units: [],
            t: [],
            ranges: []
        }
    },


}
</script>

<style lang="">

</style>